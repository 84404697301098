import React from 'react';
import CtaBlock from '../../components/components-group-one/CtaBlock';
import Footer from '../../components/footer';
import HtmlHead from '../../components/html-head';
import Navbar from '../../components/navbar';
import { PageData } from '../../types';
import ResourceHero from '../../components/resources-components/resource-hero';
import CaseStudy from '../../components/resources-components/case-study';
import hexagon from '../../assets/resources/hexagon.png';
import adam from '../../assets/resources/Adam savage.png';
import hexagonpdf from '../../assets/home-v3/Hexagon.pdf';
import { graphql } from 'gatsby';


const Hexagon: React.FC<PageData> = (resp) => {
    const seoData = resp?.data?.allContentfulPageSeoData?.edges[0]?.node;

    const resourceData = {
        formName : 'hexagon',
        companyLogo: hexagon,
        title: 'Agent Efficiency to <br/>Streamline Support',
        author: {
            image: adam,
            name: '<span>Adam</span> Savage',
            title: 'Chief Operating Officer',
            quote: `Our agent efficiency is up by 50% with AptEdge, reducing headcount and onboarding costs`
        },
        stats: [
            {
                percentage: '50%',
                title: 'Increase in Support Agent Efficiency'
            },
            {
                percentage: '60%',
                title: 'More access to information'
            },
        ],
        pdf: hexagonpdf,
        ctaTitle: "Download PDF",
        count: [
            {
                num: '730B',
                title: 'industry size'
            },
            {
                num: '50+',
                title: 'countries'
            },
            {
                num: '170',
                title: 'acquisitions'
            },
            {
                num: '24,000+',
                title: 'employees'
            }
        ],
        countColor: '#4BA5C4',
        isdownload: true
    };

    const caseStudy = [
        {
            title: 'About',
            desc: `Hexagon is a global technology company specializing in sensor, software, and autonomous technologies. 
                    They focus on providing digital solutions to enhance efficiency and productivity in a variety of industries 
                    including manufacturing, agriculture, automotive, and construction. Their approach integrates data and 
                    technology to create smart, sustainable solutions for various geospatial and industrial applications. 
                    Hexagon's vision revolves around leveraging advanced technologies to shape smart change in industries and 
                    communities around the world.`
        },
        {
            title: 'Solution',
            desc: `<span>Knowledge Unification:</span> <br />
                    Recognizing the complexities and varied nature of the companies they acquired, Hexagon sought a solution that could seamlessly integrate these diverse entities.
                    <br /><br />
                    <span>Customer Support:</span> <br />
                    AptEdge offered a more cohesive and efficient system,  to deliver a superior customer experience resulting in faster resolution times.
                    <br /><br />
                    <span>Agent Success:</span> <br />
                    Unified knowledge significantly reduced the time and effort required to locate information and respond to customer inquiries.`
        },
        {
            title: 'Challenge',
            desc: `Having acquired a variety of companies with different support systems and customer expectations, faced the challenge of integrating diverse teams and knowledge bases. 
                    <br /><br />
                    <span>Global Team Alignment</span><br />
                    Separate support teams with different sets of information.
                    <br /><br />
                    <span>Disparate Knowledge</span><br />
                    Difficulty in accessing and consolidating information across various systems.
                    <br /><br />
                    <span>Resolution Time</span><br />
                    High expectations from customers for quick and accurate solutions.`
        },

        {
            title: 'Results',
            desc: `<span>Agent Efficiency:</span> Ability to access 50-60% more information .
                    <br /><br />
                    <span>Improved Satisfaction:</span> Significant improvement in customer satisfaction from faster and accurate responses.
                    <br /><br />
                    <span>Resolution Time:</span> The increased access to information resulted in resolving tickets faster.`
        },
    ];

    return (
        <>
            <HtmlHead
                title={seoData?.title}
                metaDescription={seoData?.metaDescription?.metaDescription}
                canonicalLink={seoData?.canonicalLink}
                openGraphImage={seoData?.openGraphImage?.file?.url}
            />
            <Navbar />
            <ResourceHero data={resourceData} />
            <CaseStudy data={caseStudy} />
            <CtaBlock ctaTitle="Start Today!" />
            <Footer />
        </>
    );
}

export default Hexagon;

export const query = graphql`
    query HexagonQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/case-study/hexagon" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;